/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

/*==============================================================================

  # Local scroll 
  # function()

  ==============================================================================*/

  var bLazy = new Blazy();

  $('a').on('click touchend', function(e) {
   var el = $(this);
   var link = el.attr("href");
   window.location = link;
 });

  window.onload = function() {
    document.body.className += " loaded";
  }


/*==============================================================================
  # Local scroll 
  ==============================================================================*/

/**
 * scrollTo()
 * 
 * ScrollTo scrolls to a given element in a given time
 * ScrollTo handlers calls the function onload if a hash is present and 
 * when a linked is clicked if the hash exists on the same page
 * 
 * @param $ele Element too scroll to 
 * @param $time Scroll time in ms
 * 
 * @return scrolls to element
 *
 * @version  1.0
 */

 function scrollTo( $ele, $time ){
  var $offset = $ele.offset().top;
  $("html, body").animate({
    scrollTop: $offset+'px' 
  }, $time );
}
//ScrollTo handlers
var $hash = window.location.hash;
//If a link contains a hash and it exists on the same page, scroll to it
$( 'a' ).on( 'click', function( e ){
  var $target = $( this );
  //Do nothing if the link is a part of a no-scroll element
  if( !($target.parents( '.no-scroll' ).length) && !($target.hasClass('no-scroll')) ){
    //Get the a-tag if target is a child to the a-tag (This seams to be necessary if the link contains an svg-obj)
    if( !( $target.is( 'a' )) ){
      $target = $target.closest( 'a' );
    }
    //Check for hash, prevent default if found  
    var $href = $target.attr( 'href' ),
    $hrefIndex = $href.indexOf('#');
    //If link contains hash
    if( $hrefIndex >= 0 ){
      var $hrefLength = $href.length,
      $hash = $href.substring( $hrefIndex, $hrefLength );
      //If hash exists on this page
      if( $( $hash ).length ){
        e.preventDefault();
        scrollTo( $( $hash ), 500 );
      }
    }
  }
});

//Scrolls to hash on page load if it exists
if( $hash.length > 0 ){
  scrollTo( $( $hash ), 1500 );
}

//<!--ScrollTo handlers End-->


/*==============================================================================
  # function()
  ==============================================================================*/

  $('.hamburger').click(function(){
    $(this).toggleClass('open');
    $('.header-nav').toggleClass('open');
  });


  /* 
   * FlowUp
   *
   * Based on Eric Wenn's PullupScroll https://github.com/ericwenn/pullupscroll)
   * Changes include: 
   * - custom namespace for functions
   * - Not dependent on "$" jquery namespace
   * - Works better on items stacked on top of each other in chrome (does not flicker)
   * - Added some custom options including durations and y-displacement
   * - Added ability to control plugin via external CSS instead appending <head>
   * 
   */

   var dgPull = dgPull || {};

   (function($) {$(document).ready(function() {

    $(window).scroll(dgPull.scrollFn);
  });
 })(jQuery);

 (function ( $ ) {
  $.fn.flowUp = function(e,options) {
    var settings = $.extend({
          // Default
          translateY: 150,
          duration: .8,
          externalCSS: false
        }, options);

    $(e).addClass('pullup-element');
    $(e).each(function(i, el) {
      var el = $(el);
      if (el.visible(true)) {
        el.addClass("already-visible"); 
      }
      else {
        el.addClass('opaque');
      }
    });

      // If external CSS is not used, add CSS to head
      if(!settings.externalCSS)
      {
        $("head").append('<style>.come-in{opacity: 1; -ie-transform:translateY('+settings.translateY+'px);-webkit-transform:translateY('+settings.translateY+'px);transform:translateY('+settings.translateY+'px);-webkit-animation:come-in '+settings.duration+'s ease forwards;animation:come-in '+settings.duration+'s ease forwards}.come-in:nth-child(odd){-webkit-animation-duration:'+settings.duration+'s;animation-duration:'+settings.duration+'s}.already-visible{opacity: 1;-ie-transform:translateY(0);-webkit-transform:translateY(0);transform:translateY(0);-webkit-animation:none;animation:none}@-webkit-keyframes come-in{to{-ie-transform:translateY(0);-webkit-transform:translateY(0);transform:translateY(0)}}@keyframes come-in{to{-ie-transform:translateY(0);-webkit-transform:translateY(0);transform:translateY(0)}} .opaque { opacity: 0; }</style>');
      }
      return this;
    };
    
    // TO DO: Take out of jQuery Namespace
    $.fn.visible = function(partial) {
      var $t        = $(this),
      $w            = $(window),
      viewTop       = $w.scrollTop(),
      viewBottom    = viewTop + $w.height(),
      _top          = $t.offset().top,
      _bottom       = _top + $t.height(),
      compareTop    = partial === true ? _bottom : _top,
      compareBottom = partial === true ? _top : _bottom;
      
      return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
    };

  }( jQuery ));

 dgPull.scrollFn = function() { 
  jQuery(".pullup-element").each(function(i, el) {
    var el = jQuery(el);
    if (el.visible(true)) {
      el.addClass("come-in"); 
      el.removeClass("opaque");
    }});
}


/*==============================================================================
  <!-- End of custom javascript -->  
  ==============================================================================*/

},
finalize: function() {

  $("body").flowUp(".floor-item", { transalteY: 250, duration: 0.5 });
  $("body").flowUp(".reference__item", { transalteY: 250, duration: 0.5 });
  $("body").flowUp(".text-block", { transalteY: 250, duration: 0.5 });


}
},
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.